<template>
	<div id="st-home">
		<el-row :gutter="20">
			<el-col :span="4">
		    	<SecondMenu type="1" />
			</el-col>
			<el-col :span="20">
				<el-row :gutter="20">
				    <el-col
				      v-for="(item, index) in rows"
				      :key="index"
				      style="padding-bottom: 10px;"
				    >
				      <el-card :body-style="{ padding: '0px', display: 'flex' }">
				        <el-image
				          :src="item.avatar_url"
				          class="image"
				          fit="cover"
				          style="width: 190px; height: 190px; flex-shrink: 0;"
				        ></el-image>
				        <div style="padding: 14px; flex: 1;" >
				          <span><b>{{ item.first_name }} {{ item.last_name }}</b></span>
				          <div class="bottom" v-if="item.subject_id">
				            <!-- <div class="title">{{ $t('Subject') }}</div> -->
				            <div class="time">{{ handleSubjectName(item.subject_id) }}</div>
				          </div>
				          <div class="bottom" v-if="item.relationshipDesc">
				          	<!-- <div class="title">{{ $t('birthday') }}</div> -->
				            <div class="time">{{ item.relationshipDesc }}</div>
				          </div>
				          <div class="bottom" v-if="item.is_main==1">
				          	<!-- <div class="title">{{ $t('birthday') }}</div> -->
				            <div class="time">Main Account</div>
				          </div>
				          <!-- <div class="bottom" v-if="item.birthday">
				          	<div class="title">{{ $t('birthday') }}</div>
				            <div class="time">{{ item.birthday }}</div>
				          </div> -->
				          <!-- <div class="bottom" v-if="item.class_name">
				          	<div class="title">{{ $t('class-name') }}</div>
				            <div class="time">{{ item.class_name }}</div>
				          </div> -->
				          <div class="bottom" v-if="item.content">
				          	<div class="title">
				          		<!-- <div>{{ $t('solved-problem') }}</div> -->
				          		<div class="desc">{{ item.content || "" }}</div>
				          	</div>
				            <!-- <el-button type="text" class="button">编辑</el-button> -->
				          </div>
				        </div>
				      </el-card>
				    </el-col>
				</el-row>
			</el-col>
		</el-row>
	</div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import SecondMenu from '@/components/SecondMenu'
import StudentApi from '@/api/student'
import TimeControl from '@/utils/time-control'

export default {
	name: "st-home",
	components: {
		SecondMenu
	},
	data() {
		return {
			rows: []
		}
	},
	computed: {
		...mapGetters([
			'subjectData'
		])
	},
	created() {
		this.getApiInfoData();
		// this.$store.dispatch('GetSubjectData')
	},
	methods: {
		getApiInfoData() {
			StudentApi.profile().then(res => {
				this.rows = res.data;
				this.add_btn = true;
			});
		},
		handleDateShow(value) {
			return TimeControl.getFormatTime(value, 'L')
		},
		handleSubjectName(subject_id) {
			for(let i in this.subjectData.subject) {
				let level = this.subjectData.subject[i].children[subject_id] || '';
				if(!!level) {
					return this.subjectData.subject[i].name + ' (' + level.name + ')';
				}
			}
		}
	}
}
</script>
<style lang="less">
#st-home {
	.time {
	  font-size: 13px;
	  color: #999;
		display: inline-block;
		white-space: nowrap;
		// width: 80%;
		overflow: hidden;
		text-overflow: ellipsis;
		text-align: right;
	}

	.title {
		font-size: 13px;
		font-weight: 600;

		.desc {
			word-break: break-all;
			line-height: 1.4;
			margin-top: 5px;
			color: #666;
			display: -webkit-box;
			font-weight: 500;
			-webkit-line-clamp: 2;
			overflow: hidden;
			-webkit-box-orient: vertical;
		}
	}

	.bottom {
	  margin-top: 8px;
	  line-height: 16px;
	  display: flex;
	  justify-content: space-between;
	  align-items: center;
	}

	.button {
	  padding: 0;
	  min-height: auto;
	}

	.image {
	  width: 100%;
	  height: 250px;
	  display: block;
	}
}
</style>